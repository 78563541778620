<template>
    <v-list>
        <v-list-item v-for="zona in zonas" :key="zona">
            <v-list-item-avatar><v-icon>mdi-circle</v-icon></v-list-item-avatar>
            <v-list-item-title>{{ zona }}</v-list-item-title>
        </v-list-item>
    </v-list>
</template>

<script>
    export default {
        name: "Zonas",
        data () {
            return {
                zonas: [
                    'Cuautitlán, Cuautitlán Izcalli',
                    'Atizapán',
                    'Tlalnepantla',
                    'Naucalpan',
                    'Huixquilucan',
                    'Miguel Hidalgo',
                    'Álvaro Obregón',
                    'Benito Juárez'
                ]
            }
        }
    }
</script>

<style scoped>

</style>
